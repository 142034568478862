import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MMlogo from "../../assets/MMlogo.png";
import mobilenav from "../../assets/icons/mobilenav.png";
import search from "../../assets/icons/search.png";
import searchwhite from "../../assets/icons/searchwhite.png";
// import {subjectIcons} from '../../utils/Constant';
import Modal from "../modal/Modal";
import UserNav from "./UserNav";
import Login from "../login/Login";
import NavMenuHover from "../navbar/NavMenuHover";
import MobileNavMenu from "./MobileNavMenu";
import {
  HideLoginModal,
  ShowToastMessage,
  SubjectList,
  SubscriptionPlanList,
  UserTokenCheck,
} from "../../redux/actions";
import MainLoader from "../mainLoader/MainLoader";
import "./Navbar.css";
import { urlPath } from "../../utils/Api"; 
import SearchAutocomplete from '../search/SearchAutocomplete'; 
function NavBar({ isSearchVisible, isSubMenuVisible, setIsSubMenuVisible }) {
   
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const urlSearchedText = location?.pathname.includes("search")
    ? location?.pathname?.split("/")[2]?.split("-")[1]
    : "";
  const [isLoading, setIsLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [selectedSubMenuHeader, setSelectedSubMenuHeader] = useState({});
  const [searchText, setSearchText] = useState(urlSearchedText);
  const [searchTextError, setSearchTextError] = useState("");
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [navBarMenu, setNavBarMenu] = useState([]);
  const subjectData = useSelector(
    (state) => state.subjectStore.subjectListData
  );
  const isLoginModalVisible = useSelector(
    (state) => state.appCommon.isLoginModalVisible
  );
  const mySubscriptionDataId = useSelector(
    (state) => state.subscriptionStore.mySubscriptionData.id
  );
  const upgradeButton = useSelector(
    (state) => state.subscriptionStore.mySubscriptionData.upgrade
  );
  useEffect(() => {
    if (!isSearchVisible) {
      setSearchText("");
    }
  }, [isSearchVisible])
  useEffect(() => {
    if (isLoginModalVisible) {
      setShowLogin(true);
    }
  }, [isLoginModalVisible]);
  useEffect(() => {
    if (!showLogin) {
      dispatch(HideLoginModal());
    }
  }, [showLogin]);
  useEffect(() => {
    if (!isSubMenuVisible && selectedMenu) {
      setSelectedMenu("");
      setShowSubMenu(false);
      setSelectedSubMenuHeader("");
    }
  }, [isSubMenuVisible]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(UserTokenCheck());
    }
    dispatch(SubjectList());
    setSelectedMenu("");
    setShowSubMenu(false);
    setSelectedSubMenuHeader({});
    // dispatch(SubscriptionPlanList());
  }, []);

  useEffect(() => {
    if (subjectData.length) {
      const modifiedSubjectData = subjectData?.map((subject) => {
        return {
          ...subject,
          gamelevel: subject.gamelevel.map((data) => {
            return {
              ...data,
            };
          }),
        };
      });
      setNavBarMenu(modifiedSubjectData);
    }
  }, [subjectData]);
  useEffect(() => {
    if (selectedSubMenuHeader.id) {
      setIsSubMenuVisible(true);
      navigate(`/games/subject-${selectedSubMenuHeader.id}`);
    } else {
      setIsSubMenuVisible(false);
    }
  }, [selectedSubMenuHeader]);
  const handleLoginModalClose = () => {
    setShowLogin(false);
    setShowOtp(false);
  };
  const profileStateData = useSelector(
    (state) => state.userStore.userProfileData
  );
  const [userProfileData, setUserProfileData] = useState({});
  useEffect(() => {
    if (profileStateData?.mobile_number) handleLoginModalClose();
    setUserProfileData(profileStateData);
  }, [profileStateData]);
  useEffect(() => {
    const vaildReg = /^[a-zA-Z\s]+$/;
    if (searchText && !vaildReg.test(searchText)) {
      setSearchTextError("Please enter vaild input");
    } else {
      setTimeout(() => {
        if (searchText) {
          navigate(`/games/search-${searchText}`);
        } else if (isSearchVisible) {
          navigate("/");
        }
      }, 500);
      setSearchTextError("");
    }
  }, [searchText]);
  const handleMobileLogin = () => {
    setShowMobileMenu(false);
    setShowLogin(true);
  };
  const handleLogoutUser = () => {
    setShowUserDropdown(false);
    setShowMobileMenu(false);
    dispatch(ShowToastMessage("Logout Successful"));
    setIsLoading(true);
    setTimeout(() => {
      navigate("/");
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      window.location.reload();
    }, 2000);
  };
  const handleSearchtext = (e) => {
    setSearchText(e.target.value);
  };
  let subjectIconUrl = urlPath;
  if(urlPath !== "uat.magikmat.net") {
    subjectIconUrl = "magikmat.net";
  }
  
  return (
    <>
      <MainLoader key={'navBar'} isLoading={isLoading} />
      <Modal
        key="mobileNavModal"
        showModal={showMobileMenu}
        handleCloseModal={() => setShowMobileMenu(false)}
        className="mobile-menu-modal"
      >
        <MobileNavMenu
          handleMobileLogin={handleMobileLogin}
          setShowMobileMenu={setShowMobileMenu}
          menus={navBarMenu}
          selectedMenu={selectedMenu}
          setSelectedMenu={(val) => setSelectedMenu(val)}
          showSubMenu={showSubMenu}
          setShowSubMenu={(val) => setShowSubMenu(val)}
          setSelectedSubMenuHeader={setSelectedSubMenuHeader}
          userData={userProfileData}
          handleLogoutUser={handleLogoutUser}
          mySubscriptionDataId={mySubscriptionDataId}
          upgradeButton={upgradeButton}
        />
      </Modal>
      <Modal
        key="loginModal"
        showModal={showLogin}
        handleCloseModal={() => handleLoginModalClose()}
        className={showOtp ? "show-otp-modal" : "login-modal"}
      >
        <Login
          showOtp={showOtp}
          setShowOtp={setShowOtp}
          setIsLoading={setIsLoading}
          handleLoginModalClose={handleLoginModalClose}
        />
      </Modal>
      <div className="nav-bar-container">
        <div className="nav-bar-section nav-main-container">
          <div className="nav-bar-section nav-main-section">
            <Link to="/" className="nav-logo">
              <img src={MMlogo} alt="<MM logo>" />
            </Link>
            <div className="nav-menu-container">
              <div className="wrapper">
                {navBarMenu?.map((menu, index) => (
                  <div key={menu.name}>
                    <div
                      className={
                        menu.name === selectedMenu
                          ? "nav-menu-container-label selected"
                          : "nav-menu-container-label"
                      }
                    >
                      <div
                        className="nav-menu-container"
                        onClick={() => {
                          setSelectedMenu(menu.name);
                          setShowSubMenu(true);
                        }}
                      >
                        {menu.name}
                      </div>
                      {!showMobileMenu &&
                        showSubMenu &&
                        menu.name === selectedMenu && (
                          <NavMenuHover
                            subjectData={menu.gamelevel}
                            handleSelectedSubMenu={setSelectedSubMenuHeader}
                            handleShowSubMenu={setShowSubMenu}
                            selectedSubMenuHeader={selectedSubMenuHeader}
                            handleSelectedMenu={setSelectedMenu}
                          />
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="nav-bar-section nav-action-section"> 
            <div>  
            <SearchAutocomplete />
              {searchTextError && (
                <p className="search-error-msg">{searchTextError}</p>
              )} 
            </div>
            
            {/* {
              !mySubscriptionDataId &&
              <Link to="/subscribe">
                <button className="nav-action-subscribe">SUBSCRIBE</button>
              </Link> 
            }

            {
              upgradeButton &&
              <Link to="/upgrade">
                <button className="nav-action-subscribe">UPGRADE</button>
              </Link>
            } */}


            {userProfileData?.mobile_number ? (
              <UserNav
                showUserDropdown={showUserDropdown}
                setShowUserDropdown={setShowUserDropdown}
                handleLogoutUser={handleLogoutUser}
              />
            ) : (
              <button
                className="nav-action-login button-view"
                onClick={() => setShowLogin(true)}
              >
                Login &nbsp;
              </button>
            )} 
          </div>
          <div className="mobile-nav-content">
            <div className="mobile-search">
              <div className="mobile-search-input-container">
                <input
                  className="mobile-search-input"
                  placeholder="Search data"
                  onChange={handleSearchtext}
                  value={searchText}
                />
                <img src={searchwhite} className="search-input-icon" />
              </div>
              {searchTextError && (
                <p className="search-error-mobile">{searchTextError}</p>
              )}
            </div>
            {!showMobileMenu && (
              <button
                className="nav-bar-mobile-menu button-view"
                onClick={() => {
                  setShowMobileMenu(true);
                }}
              >
                <img src={mobilenav} alt="menu" />
              </button>
            )}
          </div>
        </div>
        {selectedSubMenuHeader.id && (
          <div className="nav-bar-section nav-course-container">
            <div className="nav-menu-item">
              {navBarMenu
                .find((menu) => menu.name === selectedMenu)
                ?.gamelevel?.map((opt, index) => (
                  <button
                    key={opt.name}
                    onClick={() => {
                      setSelectedSubMenuHeader(opt);
                    }}
                    className={
                      selectedSubMenuHeader.name === opt.name
                        ? "nav-menu-subheader button-view selected"
                        : "nav-menu-subheader button-view"
                    }
                  >
                    <img
                      className="nav-menu-subheader-img"
                      src={`https://api.${subjectIconUrl}/api/game/getlevelimage?id=${opt.id}`}
                      alt={opt.name}
                    ></img>
                    {opt.name}
                  </button>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default NavBar;